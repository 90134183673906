.ProductDetails{
    width: 100%;
    margin: 20px 0;
  }
  input:checked ~ label{
    display: none;
  }
  input{
    outline: none;
  }
  img.CarouselImage{
    width: 700px;
    height: 300px;
    object-fit: contain;
  }
  .first__varse {
    margin: auto;
    width: 50%;
  }
  .varse__2{
    width: 60%;
    margin: auto;
    overflow: hidden;
  }
  .css-14j5k7k{
    justify-content: center!important;
    align-items: center!important;
    display: flex!important;
  }
  .detailsBlock-1 > h2 {
    padding: 10px 0px;
    font-size: 35px
  }
  .detailsBlock-2 > span {
    padding: 10px 0px;
  }
  .detailsBlock-3-1 {
    display: flex;
    align-items: center;
  }
  
  .detailsBlock-3-1-1 {
    width: 60px;
    overflow: hidden;
    display: flex;
    margin: 10px;
  }
  p.noReviews {
    text-align: center;
  }
  .detailsBlock-3-1-1 > button {
    width: 100%;
    background: #3BB77E;
    border: none;
    color: #fff;
    height: 20px;
    cursor: pointer;
  }
  input[type="number"] {
    border: none;
    width: 100%;
    text-align: center;
  }
  
  @media screen and (max-width: 600px) {
  .varse__2 {
    width: 100%;
    overflow: hidden;
    text-align: center;
  }
  .detailsBlock-3-1{
    flex-direction: column;
  }
  .Description{
    padding: 0 3vmax;
  }
  .Description p{
    padding-left: 0!important;
  }
  .detailsBlock > div > h1 {
    font-size: 3vmax;
    color: #000!important;
    opacity: 1;
    padding: 1vmax 0;
  }
  .home__content{
    width: 100%!important;
    left: 0!important;
  }
  .home__content > div > h2 {font-size: 3vmax!important;
  line-height: 1.9;}
  .home__content > div > span{
    height: 30px!important;
    width: 135px!important;
  }
  button.Home__button {font-size: 2.1vmax!important;}
  .CarouselItem {
    width: 100%;
    height: 100%;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
  }
  
  }
  @media screen and (max-width: 1024px) {
    .first__varse{
      margin: unset!important;
      width: 100%;
    }
    .first__varse img{
      margin: 0;
      width: 100%;
    }
  }
  