.banner {
  height: 100vh;
  width: 100%;
  position: relative;
  background-color: #4cabdb;
}
.bgImg {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.home__content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 5%;
  display: flex;
  width: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
}
button.Home__button {
  padding: 15px 20px;
  background-color: tomato;
  border: none;
  cursor: pointer;
  color: #fff;
}
.homeHeading {
  text-align: center;
  border-bottom: 1px solid rgba(21, 21, 21, 0.5);
  width: 20vmax;
  padding: 1vmax;
  font-size: 1.4vmax;
  margin: 5vmax auto;
  color: rgb(0, 0, 0, 0.7);
}
.container {
  display: flex;
  margin: 2vmax auto;
  width: 80vw;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
}
h1.discountPrice {
  font-size: 1vmax;
  text-decoration: line-through;
  padding-left: 10px;
}
.ProductCard {
  width: 30vmax;
  height: 22vmax;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: rgb(48, 48, 48);
  margin: 2vmax;
  transition: all 0.5s;
  padding-bottom: 0.5vmax;
}
img.ProductImg {
  width: 30vmax;
}
.ProductCard > div {
  margin: 0.5vmax;
  display: flex;
  justify-content: flex-start;
}
.ProductCard > div > span {
  margin: 0.5vmax;
  font: 300 0.7vmax "Roboto";
}
.productName {
  font-family: "Roboto";
  font-size: 1.2vmax;
  margin: 1vmax 0.5vmax;
  color: #000;
  margin-bottom: 0;
}
.ProductCard > span {
  margin: 0.5vmax;
  color: tomato;
  font-family: "Frankin Gothic Medium", "Arrial Narrow", Arial, sans-serif;
  font-size: 1vmax;
}
.ProductCard:hover {
  box-shadow: 0 0 5px rgba(15, 15, 15, 0.26);
  transform: translateY(-1vmax);
}
@media screen and (max-width: 600px) {
  .ProductCard > p {
    font-size: 1.7vmax;
  }
  .ProductCard > div {
    margin: 0vmax;
    display: block;
  }
  .ProductCard > span {
    font-size: 1.5vmax;
  }
  .ProductCard > div > span {
    margin: 0 0.5vmax;
    font: 300 1vmax "Roboto";
  }
}
.banner {
  height: 100vh;
  width: 100%;
  position: relative;
  background-color: #4cabdb;
}
.bgImg {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.home__content {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 5% !important;
  display: flex;
  width: 100vh !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
button.Home__button {
  padding: 15px 20px;
  background-color: tomato;
  border: none;
  cursor: pointer;
  color: #fff;
}
.homeHeading {
  text-align: center;
  border-bottom: 1px solid rgba(21, 21, 21, 0.5);
  width: 20vmax;
  padding: 1vmax;
  font-size: 1.4vmax;
  margin: 5vmax auto;
  color: rgb(0, 0, 0, 0.7);
}
.container {
  display: flex;
  margin: 2vmax auto;
  width: 80vw;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
}
.ProductCard {
  width: 14vmax;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: rgb(48, 48, 48);
  margin: 2vmax;
  transition: all 0.5s;
  padding-bottom: 0.5vmax;
}
img.ProductImg {
  width: 14vmax;
  height: 12vmax;
  object-fit: contain;
}
.ProductCard > div {
  margin: 0.5vmax;
  display: flex;
  justify-content: flex-start;
}
.ProductCard > div > span {
  margin: 0.5vmax;
  font: 300 0.7vmax "Roboto";
}
.productName {
  font-family: "Roboto";
  font-size: 1.2vmax;
  margin: 1vmax 0.5vmax;
  color: #000;
  margin-bottom: 0;
}
.ProductCard > span {
  margin: 0.5vmax;
  color: tomato;
  font-family: "Frankin Gothic Medium", "Arrial Narrow", Arial, sans-serif;
  font-size: 1vmax;
}
.ProductCard:hover {
  box-shadow: 0 0 5px rgba(15, 15, 15, 0.26);
  transform: translateY(-1vmax);
}
@media screen and (max-width: 600px) {
  .ProductCard > p {
    font-size: 1.7vmax;
  }
  .ProductCard > div {
    margin: 0vmax;
    display: block;
  }
  .ProductCard > span {
    font-size: 1.5vmax;
  }
  .ProductCard > div > span {
    margin: 0 0.5vmax;
    font: 300 1vmax "Roboto";
  }
}
